<template>
	<div class="bg-img">
		<img class="img-bg-cls" v-if="bannerInfo" :src="bannerInfo[0]" alt="">
		<div class="chain-router">
			<router-link to="/">首页</router-link>
			<em>></em>
			<span class="active">产品中心</span>
		</div>
		<div class="product-box">
			<div class="cate-box">
				<div class="cate-item" v-for="(item, index) in cateList" :key="index">
					<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link" :class="{'active-cls': showCate[index].id == params.c_id}" @click="chooseCate(item.id, index)">
							{{showCate[index].name}}<i v-if="item.list.length" class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item  :command="{child, index}" v-for="child in item.list" :key="child.id">{{child.name}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<!-- 产品列表 -->
			<div class="product-list">
				<div class="screen-box">
					<div class="type-list">
						<div class="type-item" @click="chooseType(item.type)" :class="{'active-cls': item.type == params.type}" v-for="item in searchList" :key="item.type">{{item.name}}</div>
					</div>
					<div class="search-box">
						<input type="text" class="keyword-cls" v-model="params.search_data" placeholder="搜索关键字">
						<i class="el-icon-search" @click="searchFn"></i>
					</div>
				</div>
				<div class="list-box">
					<div class="li-cls" v-for="(item) in showList" :key="item.id" @click="goDetail(item.id)">
						<img class="li-img" :src="item.img_id" alt="">
						<div class="li-bottom">
							<div class="flex-1">
								<div class="name-cls ellipsis-text">{{item.title}}</div>
								<div class="click-num">点击数：{{item.click_num}}</div>
							</div>
							<i class="el-icon-right"></i>
						</div>
					</div>
					<!-- <el-empty description="暂无该产品,请等待产品上线~"></el-empty> -->
				</div>
				<div class="product-bottom">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :page-size="params.pagesize"
					  :total="params.pagesize * pagenum"
					  @current-change="changePage">
					</el-pagination>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		data() {
			return {
				cateList: [], // 分类
				showCate: [], // 当前显示的分类
				searchList: [], // 搜索类型
				currentCate: null,
				params: {
					page: 1,
					pagesize: 20,
					c_id: 0,
					type: 0,
					search_data: ''
				},
				showList: [],
				pagenum: null
			}
		},
		computed: {
			...mapState({
				bannerInfo: state => state.bannerInfo[7], // banner图
			})
		},
		created() {
			if(this.$route.params.key == 'c'){
				this.params.c_id = this.$route.params.c_id;
			}
			if(this.$route.params.key == 'p'){
				this.params.c_id = this.$route.params.p_id;
			}
			this.getCate();
			this.getProductList();
		},
		methods: {
			chooseCate(id) {
				this.params.c_id = id;
				this.searchFn();
			},
			// 选择分类
			handleCommand(command) {
				// console.log(command,'command')
				this.showCate[command.index] = command.child;
				this.params.c_id = command.child.id;
				this.searchFn();
			},
			// 搜索
			searchFn() {
				this.params.page = 1;
				this.getProductList();
			},
			// 选择类型
			chooseType(type) {
				this.params.type = type;
				this.searchFn();
			},
			// 获取分类
			getCate() {
				this.$api.getCate().then(res => {
					if (res.code == '0000') {
						this.cateList = res.data.cate;
						this.searchList = res.data.searchtype;
						
						let allItem = {
							name: '全部',
							id: 0,
							list: []
						}
						res.data.cate.unshift(allItem);
						
						// 遍历分类
						res.data.cate.map((item) => {
							if (item.list.length) {
								let obj = {
									id: item.id,
									name: item.name
								}
								item.list.unshift(obj);
								// 默认第一个选中
								this.showCate.push(obj);
							} else {
								this.showCate.push(item)
							}
						})
						if(this.$route.params.key == 'c'){
							// console.log(this.$route.params)
							this.showCate.forEach((item,index) =>{
								if(item.id == this.$route.params.p_id){
									// console.log(item)
									this.showCate[index] = {id:this.$route.params.c_id,name:this.$route.params.c_name}
								}
							})
							// console.log(this.showCate,'showCate')
						}
						// console.log(res.data.cate,'cate')
					}
				})
			},
			// 获取产品列表
			getProductList() {
				this.$api.getProductList(this.params).then(res => {
					if (res.code == '0000') {
						this.pagenum = res.data.pagenum;
						this.showList = res.data.list;
					}
				})
			},
			// 跳转到详情
			goDetail(id) {
				this.$router.push({
					path: `/productDetail/${id}`
				})
			},
			changePage(e) {
				this.params.page = e;
				this.getProductList();
			}
		}
	}
</script>

<style scoped lang="scss">
	.bg-img {
		padding-bottom:50;
		.img-bg-cls{
			width: 100%;
			height: 450px;
			margin-bottom: 20px;
			object-fit: cover;
		}
	}

	.product-box {
		width: 1260px;
		margin: 0 auto;
		text-align: center;
	}

	.cate-box {
		margin: 50px 0;

		.cate-item {
			display: inline-block;
			position: relative;
			font-size: 16px;
			cursor: pointer;
			padding-right: 1px;
		}

		.cate-item:after {
			position: absolute;
			right: 0;
			top: 50%;
			height: 12px;
			width: 1px;
			display: block;
			content: "";
			background: #888;
			transform: translateY(-50%);
		}

		.cate-item:last-child:after {
			display: none;
		}
		
		.el-dropdown-link{
			padding: 0 20px;
			height: 34px;
			line-height: 34px;
			border-radius: 4px;
			display: inline-block;
			outline: none;
		}
		
		.active-cls {
			color: #fff;
			background: #22529A;
		}
	}

	.product-list {
		text-align: left;

		.screen-box {
			height: 42px;
			border: 1px solid #e6e6e6;
			background: #FAFAFA;
			display: flex;
			height: 42px;
			line-height: 42px;
		}
		
		.type-list{
			flex: 1;
			display: flex;
			
			.type-item{
				width: 120px;
				text-align: center;
				border-right: 1px solid #e6e6e6;
				cursor: pointer;
				background: #ccc;
				font-size: 16px;
				transition: all .3s;
			}
			
			.type-item.active-cls{
				background: #fff;
				color: #22529A;
			}
			
			.type-item:hover{
				background: #fff;
				color: #22529A;
			}
		}
		
		.search-box{
			width: 340px;
			background: #fff;
			border-left: 1px solid #e6e6e6;
			display: flex;
			font-size: 16px;
			
			.keyword-cls{
				padding-left: 20px;
				flex: 1;
				border: none;
				outline: none;
			}
			
			.el-icon-search{
				background: #ccc;
				width: 56px;
				text-align: center;
				line-height: 44px;
				cursor: pointer;
			}
		}
		
		.list-box{
			margin-top: 20px;
			white-space: normal;
			
			.li-cls{
				width: 292px;
				background: #FFFFFF;
				border-radius: 4px;
				margin: 0 30px 30px 0;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
				display: inline-block;
				vertical-align: top;
				cursor: pointer;
				transition: all .3s;
			}
			
			
			.li-cls:nth-child(4n) {
				margin-right: 0;
			}
			
			.li-img{
				height: 270px;
				width: 270px;
				margin: 10px;
				transition: all .3s;
			}
			
			.li-bottom{
				border-top: 1px solid #f7f7f7;
				padding: 12px;
				display: flex;
				align-items: center;
			}
			
			.flex-1{
				flex: 1;
			}
			
			.name-cls{
				font-size: 16px;
				color: #666;
				width: 200px;
				height: 21px;
			}
			
			.click-num{
				font-size: 14px;
				color: #999;
			}
			
			.el-icon-right{
				font-size: 20px;
				color: #ccc;
				padding: 0 20px;
			}
		}
		
		
		.li-cls:hover{
			box-shadow: 0px 0px 6px 0px rgba(64, 158, 255, 0.5);
		}
		
		.li-cls:hover .li-img{
			transform: scale(1.05);
		}
	}
	
	.product-bottom{
		text-align: right;
	}
</style>
