<template>
	<div class="bg-img">
		<div class="main-title">
			<div class="big-title">产品中心</div>
			<div class="small-title">— Product Center —</div>
		</div>
		<div class="product-box">
			<!-- 筛选栏 -->
			<div class="screen-box" :class="{'show-search': show}">
				<div class="flex-box" v-if="cateList.length">
					<van-dropdown-menu active-color="#1989fa">
						<van-dropdown-item v-model="params.c_id" :options="cateList" @change="searchFn" />
						<van-dropdown-item v-model="params.type" :options="searchList" @change="searchFn" />
					</van-dropdown-menu>
					<van-icon name="search" class="icon-search" size="22" @click="showSearch" />
				</div>
				<div class="search-box">
					<van-search v-model="params.search_data" placeholder="请输入搜索关键词" show-action @search="searchFn" @cancel="onCancel" />
				</div>
			</div>
			
			<!-- 产品列表 -->
			<div class="list-box">
				<div class="li-cls" v-for="(item) in showList" :key="item.id" @click="goDetail(item.id)">
					<img class="li-img" :src="item.img_id" alt="">
					<div class="li-bottom">
						<div class="flex-1">
							<div class="name-cls ellipsis-text">{{item.title}}</div>
							<div class="click-num">点击数：{{item.click_num}}</div>
						</div>
						<i class="el-icon-right"></i>
					</div>
				</div>
				<!-- <el-empty description="暂无该产品,请等待产品上线~"></el-empty> -->
				<van-empty image="search" description="暂无该产品,请等待产品上线~" v-if="!showList.length" />
			</div>
			<div class="page-cls">
				<van-pagination v-model="params.page" @change="changePage" :total-items="params.pagesize * pagenum" :items-per-page="params.pagesize" />
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				cateList: [], // 分类
				showCate: [], // 当前显示的分类
				searchList: [], // 搜索类型
				currentCate: null,
				params: {
					page: 1,
					pagesize: 20,
					c_id: 0,
					type: 0,
					search_data: ''
				},
				showList: [],
				pagenum: null,
				show: false
			}
		},
		created() {
			this.getCate();
			this.getProductList();
		},
		methods: {
			chooseCate(id) {
				this.params.c_id = id;
				this.searchFn();
			},
			// 选择分类
			handleCommand(command) {
				this.showCate[command.index] = command.child;
				this.params.c_id = command.child.id;
				this.searchFn();
			},
			// 搜索
			searchFn() {
				this.params.page = 1;
				this.getProductList();
			},
			// 选择类型
			chooseType(type) {
				this.params.type = type;
				this.searchFn();
			},
			// 获取分类
			getCate() {
				this.$api.getCate().then(res => {
					if (res.code == '0000') {
						// 遍历数据，生成下拉组件数据
						res.data.cate.unshift({
							name: '全部',
							id: 0
						})
						res.data.cate.map(item => {
							item.text = item.name;
							item.value = item.id;
						})
						this.cateList = res.data.cate;
						// 遍历数据，生成下拉组件数据
						res.data.searchtype.forEach(item => {
							item.text = item.name;
							item.value = item.type;
						})
						this.searchList = res.data.searchtype;
						
						// 遍历分类
						res.data.cate.map((item) => {
							if (item.list && item.list.length) {
								let obj = {
									id: item.id,
									name: item.name
								}
								item.list.unshift(obj);
								// 默认第一个选中
								this.showCate.push(obj);
							} else {
								this.showCate.push(item)
							}
						})
						
					}
				})
			},
			// 获取产品列表
			getProductList() {
				this.$api.getProductList(this.params).then(res => {
					if (res.code == '0000') {
						this.pagenum = res.data.pagenum;
						this.showList = res.data.list;
					}
				})
			},
			// 跳转到详情
			goDetail(id) {
				this.$router.push({
					path: `/productDetail/${id}`
				})
			},
			changePage(e) {
				this.params.page = e;
				this.getProductList();
			},
			// 显示搜索栏
			showSearch() {
				this.show = !this.show;
			},
			// 关闭搜索
			onCancel() {
				this.show = false;
			}
		}
	}
</script>

<style scoped lang="scss">
	.bg-img {
		background-image: url(../assets/product_bg.png);
		padding: 3rem 0 1.6rem;
	}

	.product-box {
		width: 100%;
		text-align: center;
	}

	.screen-box {
		margin-top: 2rem;
		background: #fff;
		height: 3.2rem;
		overflow: hidden;
		transition: all .3s;
		position: sticky;
		top: 3.6rem;
		border-top: 1px solid #f5f5f5;
	
		.flex-box {
			display: flex;
			align-items: center;
		}
	
		.van-dropdown-menu {
			flex: 1;
		}
	
		.icon-search {
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
			background: #ccc;
			color: #fff;
		}
	}
	
	.screen-box.show-search {
		height: 6rem;
	}

	.list-box{
		margin-top: 1rem;
		white-space: normal;
		text-align: center;
		
		.li-cls{
			width: 10.5rem;
			background: #FFFFFF;
			border-radius: 0.4rem;
			margin: 0 .8rem .8rem 0;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
			transition: all .3s;
		}
		
		
		.li-cls:nth-child(2n) {
			margin-right: 0;
		}
		
		.li-img{
			width: 9.7rem;
			margin: 0.4rem;
			transition: all .3s;
		}
		
		.li-bottom{
			border-top: 1px solid #f7f7f7;
			padding: 0.2rem;
			display: flex;
			align-items: center;
		}
		
		.flex-1{
			flex: 1;
			text-align: left;
			padding-left: .4rem;
		}
		
		.name-cls{
			font-size: 16px;
			color: #666;
			width: 7rem;
			height: 21px;
		}
		
		.click-num{
			font-size: 14px;
			color: #999;
		}
		
		.el-icon-right{
			font-size: 20px;
			color: #ccc;
			padding: 0 20px;
		}
	}
</style>
